"use client";

import * as React from "react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { TriangleRightIcon } from "@radix-ui/react-icons";
import { cn } from "../../utils";
import { LucideIcon } from "lucide-react";

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => <AccordionPrimitive.Item ref={ref} className={cn("", className)} {...props} />);
AccordionItem.displayName = "AccordionItem";

type AccordionTriggerProps = React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & {
  caretPosition?: "left" | "right";
  Caret?: LucideIcon;
};

const AccordionTrigger = React.forwardRef<React.ElementRef<typeof AccordionPrimitive.Trigger>, AccordionTriggerProps>(
  ({ className, children, caretPosition: chevronPosition = "right", Caret, ...props }, ref) => {
    const CaretNode = Caret || TriangleRightIcon;
    return (
      <AccordionPrimitive.Header className="flex">
        <AccordionPrimitive.Trigger
          ref={ref}
          className={cn(
            "flex flex-1 items-center justify-between px-0 py-2 text-sm font-medium transition-all [&[data-state=open]>svg]:rotate-90",
            className,
          )}
          {...props}
        >
          {chevronPosition === "left" && (
            <CaretNode className="text-muted-foreground size-4 shrink-0 transition-transform duration-200" />
          )}
          <div className="text-muted-foreground flex max-w-full flex-grow justify-start truncate">{children}</div>
          {chevronPosition === "right" && (
            <CaretNode className="text-muted-foreground size-4 shrink-0 transition-transform duration-200" />
          )}
        </AccordionPrimitive.Trigger>
      </AccordionPrimitive.Header>
    );
  },
);
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className="data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down overflow-hidden text-sm"
    {...props}
  >
    <div className={cn("pb-4 pt-0", className)}>{children}</div>
  </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
